/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('.navbar-toggle').on('click', function() {
          $('body').toggleClass('menu-is-open');
        });

        var winWidth         = $(window).width();
        var mobileNavEnabled = false;

        function scrollAnchorLinks() {

          var anchors = $("a[href^='#']");

          anchors.unbind('click');

          anchors.on('click', function(e) {

            e.preventDefault();
            var elementID    = $(this).attr('href');
            var bannerHeight = winWidth > 768 ? $('.banner').height() + 74 : $('.banner').height();
            var scrollHeight = $(elementID).offset().top - bannerHeight;
            
            $('html,body').animate({
              scrollTop: scrollHeight
            }, 'slow');

          });

        }

        function alignSubNav() {

          var bannerWidth = $('.banner').width();
          var maxWidth    = bannerWidth < 1200 ? bannerWidth : 1200;
          var rightOffset = ( winWidth - maxWidth ) / 2 + 15;
          rightOffset     = rightOffset > 0 ? rightOffset + 'px' : 'inherit';

          $('.sub-menu').css('padding-right', rightOffset);

        }

        function mobileNav() {

          if ( winWidth < 768 ) {

            if ( !mobileNavEnabled ) {

              var dropdowns = $('.dropdown > a');

              dropdowns.on('click', function(e) {

                e.preventDefault();

                var hasClass = $(this).parent().hasClass('show-sub-menu');

                dropdowns.parent().removeClass('show-sub-menu');

                if ( !hasClass ) {
                  $(this).parent().addClass('show-sub-menu');
                }
                
              });

              mobileNavEnabled = true;

            }

          } else {

            if ( mobileNavEnabled ) {
              $('.dropdown > a').unbind('click');
              mobileNavEnabled = false;
            }

          }

        }

        function unbindPhotos() {

          if ( winWidth < 768 ) {
            $(".prettyphoto").each(function() {
              $(this).removeClass('prettyphoto').attr('data-rel', null).unbind('click');
            });
          }

        }

        if ( $('.slider--module').length > 0 ) {

          var slideInt = 0;

          var sliderInit = function( resized ) {

            if ( slideInt !== 0 ) { 
              clearInterval(slideInt);
            }

            $('.slider--module').each(function(index, value) {

              var winWidth         = $(window).width();
              var slider           = $(this);
              var sliderID         = slider.attr('id');
              var slides           = slider.find('.slide');
              var sliderPagination = slider.find('.slider--module-pagination');
              var slidesLength     = slides.length;
              var slideWrap        = slider.find('.slider--module-wrap');
              var slideWidth       = winWidth;

              slideWrap.css({
                'width' : ( slideWidth * slidesLength ),
                'margin-left' : 0
              });

              slides.css('width', slideWidth );
              slides.removeClass('active-slide').eq(0).addClass('active-slide');

              var slidesPagination = '';

              for (var i = 0; i < slidesLength; i++) {
                slidesPagination += '<a class="slider--module-pagination-item" data-slide="'+i+'"></a>';
              }

              sliderPagination.html(slidesPagination);

              $('.slider--module-pagination-item').removeClass('active-slide').eq(0).addClass('active-slide');

              var curSlide = slides.find('.active-slide');

              slider.unbind();

              var nextSlide = function() {

                var curActive     = slides.find('.active-slide');
                var curSlideIndex = slides.index($('#'+sliderID+' .active-slide'));
                var offset        = '';
                var nextSlide     = curSlideIndex + 1;

                if ( nextSlide >= slidesLength ) {
                  offset    = 0;
                  nextSlide = 0;
                } else {
                  offset = - ( slideWidth * nextSlide );
                }

                slideWrap.animate({
                  marginLeft : offset + 'px',
                }, 300, function() {
                  slides.removeClass('active-slide');
                  $('.slider--module-pagination-item').removeClass('active-slide');
                  slides.eq( nextSlide ).addClass('active-slide');
                  $('.slider--module-pagination-item').eq( nextSlide ).addClass('active-slide');
                });

              };

              var previousSlide = function() {
                
                var curActive     = slides.find('.active-slide');
                var curSlideIndex = slides.index($('#'+sliderID+' .active-slide'));
                var offset        = '';
                var previousSlide = curSlideIndex - 1;

                if ( previousSlide >= 0 ) {
                  offset = - ( slideWidth * previousSlide );
                } else {
                  offset        = - ( slideWidth * ( slidesLength - 1 ) );
                  previousSlide = ( slidesLength - 1 );
                }

                slideWrap.animate({
                  marginLeft : offset + 'px',
                }, 300, function() {
                  slides.removeClass('active-slide');
                  slides.eq( previousSlide ).addClass('active-slide');
                });

              };

              var toSlide = function( slideIndex ) {
                
                var curActive     = slides.find('.active-slide');
                var curSlideIndex = slides.index($('#'+sliderID+' .active-slide'));
                var offset        = '';
                var toSlideIndex  = slideIndex;

                if ( toSlideIndex !== curSlideIndex ) {
                  offset = - ( slideWidth * toSlideIndex );
                } else {
                  return;
                }

                slideWrap.animate({
                  marginLeft : offset + 'px',
                }, 300, function() {
                  slides.removeClass('active-slide');
                  $('.slider--module-pagination-item').removeClass('active-slide');
                  slides.eq( toSlideIndex ).addClass('active-slide');
                  $('.slider--module-pagination-item').eq( toSlideIndex ).addClass('active-slide');
                });

              };

              var autoSlide = function() {

                slideInt = setInterval(function() {
                  nextSlide();
                }, 10000);

              };

              autoSlide();

              slider.on('click', '.slider--module-pagination-item', function(e) {
                
                e.preventDefault();
                //console.log($('.slider--module-wrap').is(':animated'));

                if( !$('.slider--module-wrap').is(':animated') ) {
                  //console.log(slideInt);
                  clearInterval(slideInt);
                  toSlide( $(this).data('slide') );
                  autoSlide();
                }

              });

              slider.on('click', '.slider--previous', function(e) {
                e.preventDefault();
                clearInterval(slideInt);
                previousSlide();
                autoSlide();
              });

              slider.on('click', '.slider--next', function(e) {

                e.preventDefault();

                if( !$('.slider--module-wrap').is(':animated') ) {
                  clearInterval(slideInt);
                  nextSlide();
                  autoSlide();
                }

              });

            });

            

          };

          sliderInit();

          $( window ).resize(function() {
            clearInterval(slideInt);
            sliderInit( true );
          });

        }

        $( window ).resize(function() {
          winWidth = $(window).width();
          mobileNav();
          alignSubNav();
          scrollAnchorLinks();
          unbindPhotos();
        });

        mobileNav();
        alignSubNav();
        scrollAnchorLinks();
        unbindPhotos();

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
